import { Link, useSearchParams, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getStoreDetail, getNaverMapInfo, createItem, updateItem } from "../store/api";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { serialize } from "../utils/common";

function StoreDetail() {
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());

    const { idx } = useParams();
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { isLoading, error, data } = useQuery(["getStoreDetail", idx], async () => {
        const data = await getStoreDetail(idx);
        console.log(data);
        return data;
    });

    const getNaverMapInfoMutation = useMutation(getNaverMapInfo);
    const createMutation = useMutation(createItem);
    const updateMutation = useMutation(updateItem);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return "Error: " + error.message;
    }

    function handleSubmit(e) {
        e.preventDefault();
        const newData = Object.fromEntries(new FormData(e.target).entries());
        newData.idx = idx;
        newData.table = "V2_TASTY_tbl";
        updateMutation.mutate(newData);

        // 캐쉬 갱신한다! 필수!!
        queryClient.setQueryData(["getStoreDetail", idx], newData);

        setTimeout(() => {
            navigate(`/adm?${serialize(params)}`);
        }, 200);

        toast("저장되었습니다.");
    }

    async function getNaverInfo() {
        const naver_id = document.querySelector(`input[name=naver_id]`).value;
        const data = await getNaverMapInfoMutation.mutateAsync({
            naver_id,
        });
        console.log(data);
        if (data.code === 0) {
            alert(data.msg);
            return;
        }
        document.querySelector(`textarea[name=memo]`).value = data.memo;
        document.querySelector(`textarea[name=menu_info]`).value = data.menu_info;
        document.querySelector(`textarea[name=open_day]`).value = data.open_day;
        document.querySelector(`input[name=off_day]`).value = data.off_day;
        document.querySelector(`input[name=tel]`).value = data.tel;
        document.querySelector(`input[name=addr]`).value = data.addr;
        document.querySelector(`input[name=lat]`).value = data.lat;
        document.querySelector(`input[name=lng]`).value = data.lng;
    }

    return (
        <div className="container">
            <div className="position-fixed w-100" style={{ top: "0px", left: "0px" }}>
                <div className="d-flex flex-row justify-content-between mb-4">
                    <Link to={`/adm?${serialize(params)}`} className="btn btn-dark rounded-0">
                        목록
                    </Link>

                    <div style={{ fontSize: "12px" }} className="d-flex align-items-center">
                        {data.cate1} {data.cate2} {data.cate3} {data.cate4} {data.cate5}
                    </div>
                    {/* <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() => {
                            if (window.confirm("복사 하시겠습니까?")) {
                                const newData = {
                                    ...data,
                                    table: "V2_TASTY_tbl",
                                };
                                delete newData.idx;
                                delete newData.created;
                                delete newData.modified;

                                createMutation.mutate(newData);
                            }
                        }}
                    >
                        {createMutation.isLoading && <i className="bi bi-upload"></i>} 복사하기
                    </button> */}

                    <button type="button" className="btn btn-dark rounded-0" onClick={() => getNaverInfo()}>
                        {getNaverMapInfoMutation.isLoading && <i className="bi bi-upload"></i>} 데어터가져오기
                    </button>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="py-5" key="asd">
                <table className="table table-sm table-bordered" style={{ fontSize: "12px" }}>
                    <tbody>
                        {Object.keys(data).map((row, i) =>
                            ["created", "modified"].includes(row) ? (
                                <tr key={i}></tr>
                            ) : (
                                <tr key={i}>
                                    <td width={100}>{row}</td>
                                    <td className="p-0">
                                        {["memo", "menu_info", "reviews", "open_day"].includes(row) ? (
                                            <textarea
                                                className="form-control form-control-sm border-0 rounded-0 shadow-none"
                                                rows="10"
                                                name={row}
                                                style={{ fontSize: "12px" }}
                                                defaultValue={data[row]}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={row}
                                                style={{ fontSize: "12px" }}
                                                className="form-control form-control-sm border-0 rounded-0 shadow-none"
                                                defaultValue={data[row]}
                                            />
                                        )}
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
                {/* {Object.keys(data).map((row, i) => (
                    <div key={i}>
                        {["created", "modified"].includes(row) ? (
                            <></>
                        ) : (
                            <div className="mb-1 row">
                                <label className="col-sm-3 col-form-label">{row}</label>
                                <div className="col-sm-7">
                                    {["memo", "menu_info", "reviews"].includes(row) ? (
                                        <textarea
                                            className="form-control form-control-sm"
                                            rows="10"
                                            name={row}
                                            defaultValue={data[row]}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            name={row}
                                            className="form-control form-control-sm"
                                            defaultValue={data[row]}
                                        />
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-warning w-100"
                                        onClick={() => {
                                            //개별 수정!
                                            try {
                                                const v = document.querySelector(`input[name=${row}]`).value;
                                                data[row] = v;
                                                updateMutation.mutate({
                                                    idx: idx,
                                                    table: "V2_TASTY_tbl",
                                                    [row]: v,
                                                });
                                            } catch (error) {
                                                const v = document.querySelector(`textarea[name=${row}]`).value;
                                                data[row] = v;
                                                updateMutation.mutate({
                                                    idx: idx,
                                                    table: "V2_TASTY_tbl",
                                                    [row]: v,
                                                });
                                            }
                                        }}
                                    >
                                        {updateMutation.isLoading ? <i className="bi bi-upload"></i> : ""}
                                        수정
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))} */}

                <div className="position-fixed w-100" style={{ bottom: "0px", right: "0px" }}>
                    <button className="btn btn-primary w-100 rounded-0" type="submit">
                        {updateMutation.isLoading ? <i className="bi bi-upload"></i> : ""}
                        저장하기
                    </button>
                </div>

                <div className="position-fixed" style={{ bottom: "20px", right: "20px" }}>
                    {/* <button
                    type="button"
                    className="btn btn-sm btn-dark w-100"
                    onClick={async () => {
                        const addr = document.querySelector(`input[name=addr]`).value;
                        const url = `https://images.lazulsoft.com/geocode/${addr}`;
                        console.log(url);
                        const { data } = await axios.get(url);
                        console.log(data);
                        document.querySelector(`input[name=lat]`).value = data.LAT;
                        document.querySelector(`input[name=lng]`).value = data.LNG;
                    }}
                >
                    주소로
                    <br />
                    위도경도
                    <br />
                    가져오기
                </button> */}
                </div>
            </form>
        </div>
    );
}

export default StoreDetail;
