import { useState } from "react";
import { useMutation } from "react-query";
import { useEffect, useRef } from "react";
import { setUserSession, getSave } from "../utils/common";
import { loginItem } from "../store/api";
import { getUser } from "../utils/common";

export default function Login() {
    const [visible, setVisible] = useState("d-none");
    const inputId = useRef();
    const inputRemember = useRef();
    const loginMutation = useMutation(loginItem);

    const user = getUser();

    useEffect(() => {
        if (user) {
            if (window.confirm("이미 로그인 되어있습니다. 관리자 페이지로 이동 하시겠습니까?")) {
                window.location.href = "/adm";
                return;
            }
        }

        setVisible("");

        loadPicoCSS();
        inputId.current.focus();
        const save = getSave() ?? {};
        inputId.current.value = save.id || "";
        inputRemember.current.checked = save.id ? true : false;
    }, []);

    function loadPicoCSS() {
        const pico = document.createElement("link");
        pico.rel = "stylesheet";
        pico.href = "/pico.min.css";
        document.head.appendChild(pico);
        document.querySelector("html").setAttribute("data-theme", "light");
    }

    async function handleSubmit(e) {
        e.preventDefault();

        //유용한놈!
        const params = Object.fromEntries(new FormData(e.target).entries());
        const data = await loginMutation.mutateAsync(params);

        if (data.code === 1) {
            try {
                setUserSession(data.save, data.user, data.access_token, data.refresh_token);
                window.location.href = "/adm";
            } catch (error) {
                console.log(error);
            }
        } else {
            alert(data.msg);
        }
    }

    return (
        <>
            <main className={`container ${visible}`}>
                <article className="grid">
                    <div>
                        <hgroup>
                            <h1>로그인</h1>
                            <h2>A minimalist layout for Login pages</h2>
                        </hgroup>
                        <form onSubmit={handleSubmit}>
                            <input
                                ref={inputId}
                                type="text"
                                placeholder="ID"
                                name="id"
                                autoComplete="username"
                                required
                            />
                            <input
                                type="password"
                                placeholder="PW"
                                name="pw"
                                autoComplete="current-password"
                                required
                            />
                            <fieldset>
                                <label htmlFor="remember">
                                    <input ref={inputRemember} type="checkbox" role="switch" name="remember" />
                                    Save ID
                                </label>
                            </fieldset>
                            <button type="submit" className="contrast">
                                Login
                            </button>
                        </form>
                    </div>
                    <div></div>
                </article>
            </main>

            <style jsx="true">{`
                /* Grid */
                #root > main {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: calc(100vh);
                    padding: 1rem 0;
                }

                article {
                    padding: 0;
                    overflow: hidden;
                }

                article div {
                    padding: 1rem;
                }

                @media (min-width: 576px) {
                    body > main {
                        padding: 1.25rem 0;
                    }

                    article div {
                        padding: 1.25rem;
                    }
                }

                @media (min-width: 768px) {
                    body > main {
                        padding: 1.5rem 0;
                    }

                    article div {
                        padding: 1.5rem;
                    }
                }

                @media (min-width: 992px) {
                    body > main {
                        padding: 1.75rem 0;
                    }

                    article div {
                        padding: 1.75rem;
                    }
                }

                @media (min-width: 1200px) {
                    body > main {
                        padding: 2rem 0;
                    }

                    article div {
                        padding: 2rem;
                    }
                }

                /* Nav */
                summary[role="link"].secondary:is([aria-current], :hover, :active, :focus) {
                    background-color: transparent;
                    color: var(--secondary-hover);
                }

                /* Hero Image */
                article div:nth-of-type(2) {
                    display: none;
                    background-color: #374956;
                    background-image: url("https://picocss.com/examples/sign-in/assets/alessio-soggetti-8jeWeKdygfk-unsplash-1000x1200.jpg");
                    background-position: center;
                    background-size: cover;
                }

                @media (min-width: 992px) {
                    .grid > div:nth-of-type(2) {
                        display: block;
                    }
                }
            `}</style>
        </>
    );
}
