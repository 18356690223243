const moment = require("moment");

export const getUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
        const user = JSON.parse(userStr);
        return user;
    }
    return null;
};

export const getSave = () => {
    const saveStr = localStorage.getItem("save");
    if (saveStr) {
        const save = JSON.parse(saveStr);
        return save;
    }
    return null;
};

export const setAccessToken = (token) => {
    localStorage.setItem("access_token", token);
};

export const getAccessToken = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
        return token;
    }
    return null;
};

export const getRefreshToken = () => {
    const token = localStorage.getItem("refresh_token");
    if (token) {
        return token;
    }
    return null;
};

export const removeUserSession = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

export const setUserSession = (save, user, accessToken, refreshToken) => {
    if (save.id) {
        localStorage.setItem("save", JSON.stringify(save));
    } else {
        localStorage.removeItem("save");
    }
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
};

export const utilConvertToMillis = (time) => {
    const dateTime = new Date(time).getTime();
    const nowTime = Math.floor(new Date().getTime());
    const date = moment(dateTime);
    const now = moment(nowTime);
    var diff = now.diff(date, "seconds");
    if (diff < 60) {
        return `방금`;
    }
    diff = now.diff(date, "minutes");
    if (diff < 60) {
        return `${diff}분 전`;
    }
    diff = now.diff(date, "hours");
    if (diff < 24) {
        return `${diff}시간 전`;
    }
    diff = now.diff(date, "days");
    if (diff < 7) {
        return `${diff}일 전`;
    }
    diff = now.diff(date, "weeks");
    if (diff < 4) {
        return `${diff}주 전`;
    }
    diff = now.diff(date, "months");
    if (diff < 12) {
        return `${diff}달 전`;
    }
    diff = now.diff(date, "years");
    return `${diff}년 전`;
};

export const replaceAll = (str, searchStr, replaceStr) => {
    if (str === "") {
        return str;
    }
    return str.split(searchStr).join(replaceStr);
};

export const getToday = () => {
    const now = new Date();
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDate = now.getDate();
    return `${todayYear}-${todayMonth}-${todayDate}`;
};

export const getTodayTime = () => {
    const now = new Date(); // 현재 날짜 및 시간
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDate = now.getDate();
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const dayOfWeek = week[now.getDay()];
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${todayYear}년 ${todayMonth}월 ${todayDate}일 ${hours}시 ${minutes}분 ${dayOfWeek}요일`;
};

export function serialize(obj) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(obj)) {
        params.append(key, value);
    }
    return params.toString();
}
