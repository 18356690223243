import { useQuery } from "react-query";
import { getStoreList } from "../store/api";
import { Link, useSearchParams } from "react-router-dom";
import { serialize } from "../utils/common";
import Pager from "../components/Pager";
import Loading from "../components/Loading";

const defaultParams = {
    search_column: "title",
    search_value: "",
    page: 1,
    is_youtube: 0,
    is_dev_recommend: 0,
    is_bib: 0,
    is_not_naver_id: 0,
};

export default function StoreList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = {
        ...defaultParams,
        ...Object.fromEntries(searchParams.entries()),
    };

    console.log(params);

    const { isLoading, error, data } = useQuery(["getStoreList", params], async () => {
        const data = await getStoreList(params);
        console.log(data);
        return data;
    });

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return "Error: " + error.message;
    }

    if (!data) {
        return "Data is null: ";
    }

    function handleSearch(e) {
        e.preventDefault();
        const search_column = e.target.search_column.value;
        const search_value = e.target.search_value.value;

        setSearchParams({
            ...defaultParams,
            search_column,
            search_value,
        });
    }

    return (
        <div className="container">
            <form onSubmit={handleSearch} className="my-3">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-6">
                        <div className="input-group input-group-sm">
                            <select className="form-select" name="search_column" defaultValue={params.search_column}>
                                <option value="title">title</option>
                                <option value="addr">addr</option>
                                <option value="naver_id">naver_id</option>
                            </select>

                            <input
                                type="text"
                                className="form-control"
                                placeholder="검색어"
                                name="search_value"
                                defaultValue={params.search_value}
                            />
                            <button className="btn btn-primary" type="submit">
                                검색
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-dark"
                                onClick={() => {
                                    setSearchParams(defaultParams);
                                    document.querySelector("select[name='search_column']").options[0].selected = true;
                                    document.querySelector("input[name='search_value']").value = "";
                                }}
                            >
                                목록
                            </button>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row mt-3" style={{ fontSize: "12px" }}>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            onChange={(e) => {
                                setSearchParams({
                                    ...params,
                                    page: 1,
                                    is_youtube: e.target.checked ? 1 : 0,
                                });
                            }}
                            checked={params.is_youtube == 1}
                        />{" "}
                        is_youtube
                    </div>
                    <div className="form-check form-switch ms-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            onChange={(e) => {
                                setSearchParams({
                                    ...params,
                                    page: 1,
                                    is_dev_recommend: e.target.checked ? 1 : 0,
                                });
                            }}
                            checked={params.is_dev_recommend == 1}
                        />{" "}
                        is_dev_recommend
                    </div>

                    <div className="form-check form-switch ms-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            onChange={(e) => {
                                setSearchParams({
                                    ...params,
                                    page: 1,
                                    is_bib: e.target.checked ? 1 : 0,
                                });
                            }}
                            checked={params.is_bib == 1}
                        />{" "}
                        is_bib
                    </div>

                    <div className="form-check form-switch ms-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            onChange={(e) => {
                                setSearchParams({
                                    ...params,
                                    page: 1,
                                    is_not_naver_id: e.target.checked ? 1 : 0,
                                });
                            }}
                            checked={params.is_not_naver_id == 1}
                        />{" "}
                        is_not_naver_id
                    </div>
                </div>
            </form>

            <div className="border rounded p-2 mb-2 shadow-sm bg-white">
                <table className="table table-sm table-hover" style={{ fontSize: "12px" }}>
                    <tbody>
                        <tr className="text-center">
                            <th>idx</th>
                            <th>title</th>
                            {/* <th>addr</th> */}
                            <th>is_youtube</th>
                            <th>is_dev_recommend</th>
                            <th>cate</th>
                        </tr>
                        {data.list.map((row, i) => (
                            <tr key={i}>
                                <td className="text-center">{row.idx}</td>
                                <td>
                                    <Link to={`/adm/${row.idx}?${serialize(params)}`}>{row.title}</Link>
                                </td>
                                {/* <td className="text-start">{row.addr}</td> */}
                                <td className="text-center">{row.is_youtube}</td>
                                <td className="text-center">{row.is_dev_recommend}</td>
                                <td className="text-start">
                                    #{row.cate2}#{row.cate3}#{row.cate4}#{row.cate5}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Pager pageHelper={data.page_helper} />

            <div className="position-fixed" style={{ bottom: "20px", right: "20px" }}>
                <Link to={`/adm/add?${serialize(params)}`}>
                    <button className="btn btn-primary btn-sm">
                        <i className="bi bi-pen"></i>
                    </button>
                </Link>
            </div>
        </div>
    );
}
