import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "./css/bootstrap.icons.css";
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { getUser } from "./utils/common";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import StoreList from "./pages/StoreList";
import NotFound from "./pages/NotFound";
import StoreDetail from "./pages/StoreDetail";
import StoreAdd from "./pages/StoreAdd";

console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
}

function App() {
    const user = getUser();

    if (!user) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/adm/login" element={<Login />}></Route>
                    <Route path="*" element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFound />}></Route>
                <Route path="/adm/login" element={<Login />}></Route>
                <Route path="/adm" element={<StoreList />}></Route>
                <Route path="/adm/:idx" element={<StoreDetail />}></Route>
                <Route path="/adm/add" element={<StoreAdd />}></Route>
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
