import axios from "axios";
import { getAccessToken, getRefreshToken, setAccessToken, removeUserSession } from "../utils/common";

export const jwtTokenVerify = async () => {
    const { data } = await axios({
        method: "POST",
        url: "/jwt_token_verify",
        data: {
            access_token: getAccessToken(),
            refresh_token: getRefreshToken(),
        },
    });

    if (data.is_expried) {
        if (data.access_token) {
            setAccessToken(data.access_token);
        } else {
            // 리프레시 토큰도 만료되었다면..
            removeUserSession();
            return false;
        }
    }
};

export const getStoreList = async (params) => {
    await jwtTokenVerify();
    const { page, search_column, search_value, is_youtube, is_dev_recommend, is_bib, is_not_naver_id } = params;
    const url = `/v2_adm/list?page=${page}&search_column=${search_column}&search_value=${search_value}&is_youtube=${is_youtube}&is_dev_recommend=${is_dev_recommend}&is_bib=${is_bib}&is_not_naver_id=${is_not_naver_id}`;
    console.log(url);
    const { data } = await axios({
        url,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return data;
};

export const getStoreDetail = async (idx) => {
    console.log("getStoreDetail", idx);
    await jwtTokenVerify();
    const url = `/v2_adm/list/${idx}`;
    console.log(url);
    const { data } = await axios({
        url,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return data;
};

export const getNaverMapInfo = async (params) => {
    await jwtTokenVerify();
    const { naver_id } = params;
    const url = `/v2_adm/get_naver_map_info?naver_id=${naver_id}`;
    const { data } = await axios({
        url,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return data;
};

export const loginItem = async (params) => {
    const url = `/v2_adm/login`;
    const { data } = await axios({
        url,
        method: "POST",
        data: params,
        headers: {
            "Content-Type": "application/json",
        },
    });
    return data;
};

export const updateItem = async (params) => {
    await jwtTokenVerify();
    const url = `/cud`;
    console.log(url);
    const { data } = await axios({
        url,
        method: "PUT",
        data: params,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return data;
};

export const createItem = async (params) => {
    await jwtTokenVerify();
    const url = `/cud`;
    console.log(url);
    const { data } = await axios({
        url,
        method: "POST",
        data: params,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return data;
};

export const deleteItem = async (idx) => {
    await jwtTokenVerify();
    const url = `/cud`;
    console.log(url);
    const { data } = await axios({
        url,
        method: "DELETE",
        data: idx,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return data;
};
