import { useLocation, useNavigate } from "react-router-dom";

export default function Pager({ pageHelper }) {
    const navigate = useNavigate();
    const location = useLocation();

    function movePage(page) {
        const params = new URLSearchParams(location.search);
        params.set("page", page);
        navigate(`${location.pathname}?${params.toString()}`);
    }

    if (!pageHelper.pnTotal) {
        return "데이터가 없습니다.";
    }

    const pageLength = pageHelper.pnEnd - pageHelper.pnStart + 1;
    if (pageLength < 1) {
        return "Error: pageLength < 1";
    }

    return (
        <div className="d-flex justify-content-center">
            <ul className="pagination pagination-sm mb-0">
                <li className={`page-item ${pageHelper.pnPrev === 0 && "disabled"}`}>
                    <button onClick={() => movePage(pageHelper.pnPrev)} className="page-link">
                        <i className="bi bi-chevron-left"></i>
                    </button>
                </li>
                {new Array(pageLength).fill().map((_, i) => (
                    <li key={i} className={`page-item ${pageHelper.pageNum === pageHelper.pnStart + i && "active"}`}>
                        <button onClick={() => movePage(pageHelper.pnStart + i)} className="page-link">
                            {pageHelper.pnStart + i}
                        </button>
                    </li>
                ))}

                <li className={`page-item ${pageHelper.pnNext === 0 && "disabled"}`}>
                    <button onClick={() => movePage(pageHelper.pnNext)} className="page-link">
                        <i className="bi bi-chevron-right"></i>
                    </button>
                </li>
            </ul>
            
        </div>
    );
}
