import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { createItem } from "../store/api";
import Loading from "../components/Loading";
import { serialize } from "../utils/common";

function StoreAdd() {
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());

    const navigate = useNavigate();

    const createMutation = useMutation(createItem);

    async function handleSubmit(e) {
        e.preventDefault();
        await createMutation.mutateAsync({
            table: "V2_TASTY_tbl",
            title: e.target.title.value,
            pkey: 1,
            naver_id: e.target.naver_id.value,
            lat: 0,
            lng: 0,
            visit_count: 0,
            cate5: e.target.cate5.value,
            is_youtube: e.target.is_youtube.value,
            is_dev_recommend: e.target.is_dev_recommend.value,
            is_bib: e.target.is_bib.value,
        });

        navigate(`/adm?${serialize(params)}`);
    }

    if (createMutation.isLoading) {
        return <Loading />;
    }

    return (
        <div className="container">
            <form onSubmit={handleSubmit} className="py-4">
                <div className="mb-1 row">
                    <label className="col-sm-3 col-form-label">title</label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            name="title"
                            className="form-control form-control-sm"
                            defaultValue=""
                            required
                        />
                    </div>
                </div>

                <div className="mb-1 row">
                    <label className="col-sm-3 col-form-label">naver_id</label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            name="naver_id"
                            className="form-control form-control-sm"
                            defaultValue=""
                            required
                        />
                    </div>
                </div>

                <div className="mb-1 row">
                    <label className="col-sm-3 col-form-label">cate5</label>
                    <div className="col-sm-9">
                        <input type="text" name="cate5" className="form-control form-control-sm" defaultValue="" />
                    </div>
                </div>

                <div className="mb-1 row">
                    <label className="col-sm-3 col-form-label">is_youtube</label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            name="is_youtube"
                            className="form-control form-control-sm"
                            defaultValue="0"
                        />
                    </div>
                </div>

                <div className="mb-1 row">
                    <label className="col-sm-3 col-form-label">is_dev_recommend</label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            name="is_dev_recommend"
                            className="form-control form-control-sm"
                            defaultValue="0"
                        />
                    </div>
                </div>

                <div className="mb-1 row">
                    <label className="col-sm-3 col-form-label">is_bib</label>
                    <div className="col-sm-9">
                        <input type="text" name="is_bib" className="form-control form-control-sm" defaultValue="0" />
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-end">
                    <button type="submit" className="btn btn-sm btn-primary">
                        <i className="bi bi-pen"></i> 저장
                    </button>
                </div>
            </form>
        </div>
    );
}

export default StoreAdd;
